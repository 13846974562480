module.exports = [{
      plugin: require('/Users/jackedgson/Documents/turboclicks/qld sports aviators/website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760},
    },{
      plugin: require('/Users/jackedgson/Documents/turboclicks/qld sports aviators/website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M558M6K","includeInDevelopment":true},
    },{
      plugin: require('/Users/jackedgson/Documents/turboclicks/qld sports aviators/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
